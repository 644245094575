:root, [data-bs-theme=light] {
  --bs-blue: #f15e75;
  --bs-primary: #fff5ac;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #f15e75;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #d1475d;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem; 
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');

body{font-family: "Source Sans 3", sans-serif !important;}

.yellowtail-regular {
  font-family: "Yellowtail", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  margin-bottom: 8px;
}
.arbn-logo-icon {
  width: 50px;
  height: 50px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}
.flex.flex-reviews-name {
  justify-content: space-between;
  max-width: 500px;
  margin-top: 70px;     color: #fff;
}

.SectionAibnbReviews-text h2 {
  font-size: 30px;
  margin-bottom: 16px;
  color: #fff;
  font-family: "Work Sans", sans-serif;
}
.Source-Sans-3{ font-family: "Source Sans 3", sans-serif;}
.nc-ExperiencesCard .nc-ExperiencesCard.group:hover {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
h1, h2, h3, h4, h5, h6{font-family: "Work Sans", sans-serif;  }
.text-user-inf h5 {
  font-weight: 500;
  font-family: "Source Sans 3", sans-serif;
  font-size: 15px;
}
.nc-Footer p{color: #fff;}
.nc-Footer h2 {
  font-size: 20px !important;
  text-transform: uppercase;
}
.border-left-footer {
  height: 100%;
  background: #4c4c4c;
  width: 1px;
}
.container-ffoter-border .border {
  background-color: #4c4c4c !important;
  height: 1px;
  border: 0;
}
.nc-Footer a:hover{color: #fff;}
.border-width-1{border-color: #232323;}
.text-user-inf p{
  font-family: "Source Sans 3", sans-serif;
    font-weight: bold;
    font-size: 17px;
    color: #111827;
    overflow: hidden;
    white-space: pre-line;
    text-overflow: ellipsis;
    min-height: 47px;
}
.nc-SectionGridSimilarListing h2, .nc-Section-Heading h2
{color:#5F5C5C; text-transform: uppercase; font-size: 40px;}
.font-size-14{font-size: 14px;}
.bg-primary-6000 {
  background-color: var(--bs-primary) !important; color: var(--bs-dark) !important;
}
.nc-ListingStayDetailPage .nc-Section-Heading h2, .nc-ListingStayDetailPage .nc-Section-Heading span {
  text-align: left;
}
.nc-Header {
  border-bottom: solid 0px #ededed !important;
}
.bg-primary-color{background-color: var(--bs-primary) !important;  color: var(--bs-dark) !important;}


.top-discover {
  background-color: #fff5ac;
  padding: 5px;
  text-align: center;
  color: #000;
  font-weight: 600;
}

a.ttnc-logo svg {
  width: 125px;
}

.lg-container {
  max-width: 100%;
  padding-right: 128px;
  padding-left: 128px;
}
.w-15-btn{width: 150px; height: 56px !important;}

.hover-bg-color:hover .hover-color-card-btn {
  background: #707070;
}
.hover-bg-color:hover .hover-color-card-btn span,
.hover-bg-color:hover .hover-color-card-btn i{color:#fff}
.hover-color-card-btn {
  border-top: solid 1px #e3e3e3;
}
.text-neutral-500 {
  color: #111827;
}
.text-neutral-18 {    font-size: 18px !important;
}
.bottom-3rem {
  bottom: -3rem !important;
}
.nc-Footer a {
  color: #fff;
}
.sm-hedding-text h2 {
  font-size: 24px;
  text-decoration: none;
  border: 0 !important;
  font-family: "Source Sans 3", sans-serif;
  font-weight: 600 !important;
}
.sm-hedding-text img.object-cover.filter-grayscale.max-h70 {
  max-height: 45px;
}
.ng-width-940 > div {
  width: 50%;
}
.ng-width-940 {
  background: #F2F2F2;
}
.ng-width-940 .flex-shrink-0.mb-10.lg\:mb-0.lg\:mr-10.lg\:w-2\/5 {
  padding: 0 64px;
}
.ng-width-940 h2.font-semibold.md\:text-5xl.text-5xl {
  color: #5F5C5C;
}
.class-SectionAibnbReviews {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #969696;
}
.class-SectionAibnbReviews > div {
  width: 50%;
}
 

.class-SectionAibnbReviews .max-w-2xl {
  margin: 0;
}
.class-SectionAibnbReviews .nc-SectionAibnbReviews.hedding-center.line-bottom-0 {
  padding: 0 75px;
}
.class-SectionAibnbReviews h2.text-5xl.md\:text-5xl.font-semibold {
  font-size: 25px;
  text-align: left !important;
  color: #fff;
}
.nc-SectionAibnbReviews.hedding-center.sm-hedding-text {
  padding: 0 50px;
  padding-bottom: 50px;
}
.reviews-comment p{color: #fff;}
.glide__slides{align-items: center;}
.reviews-comment p {
  max-width: 500px;
}
.icon-top-mid {
  position: relative;
  top: -60px;
  left: 101px;
}
.reviews-comment {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
} 
.SectionAibnbReviews-text{
  padding: 0 75px;
}

.lg\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
}
.flex.flex-login {
  background: #fff;
  box-shadow: 10px 15px 15px #00000029;
  border-radius: 20px;overflow: hidden;
  border:solid 1px #00000029;
}

.facebook-login, .gmail-login {
  background: none;
  border: solid 1px #1877F2;
  color: #1877F2 !important;
  border-radius: 20px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: 'Source Sans 3';
  font-size: 17px;
}
.flex.flex-login form.grid.grid-cols-1.gap-6 input {
  background: #F2F2F2;
    border-radius: 32px;
    color: #5F5C5C;
    font-family: 'Source Sans 3';
    font-weight: 500;
}.password-create-acc a, .password-create-acc span {
  color: #1877F2;
  font-family: 'Source Sans 3';
  font-size: 16px !important;
}
.btn-bold {
  font-size: 16px !important; font-weight: 500;
}
.password-create-acc{margin-bottom: 35px !important;}
.password-create-acc a, .password-create-acc span {
  color: #1877F2;
  font-family: 'Source Sans 3';
}
.nav-home1 .lg-container {
  background: transparent !important;
}
.nc-MainNav1.w-full.z-10.onTop .bg-color {
  background: #000;
}
.nc-MainNav1 svg.logo-Tiny-away path#path0 {
  fill: #fff;
}
.nc-MainNav1 svg.h-7.w-7 path {
  fill: #fff;
}
i.cartIcon svg path {
  fill: #fff;
}
a.user-link {
  background: #FFF5AC;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 35px;
  border-radius: 100%;
  color: #000;
}

a.user-link i.text-lg.las.la-user {

  border: solid 1px #000;
  border-radius: 100%;
}
a.user-link:hover{background: #f5e88b; color: #000;}
a.user-link:hover .la-user:before {
  color: #000;
}span.custome-search-style-sticy > div {
  color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 300px;
  font-size: 15px !important;
}
.w-15-btn .bg-primary-6000 {
  background-color: var(--bs-primary) !important;
  color: var(--bs-dark) !important;
  width: 100%;
}

span.custome-search-style-sticy h2{font-size: 20px; color: #fff;}
span.custome-search-style-sticy sup {
  position: static;
  margin-right: 2px;
  font-size: 20px;
  line-height: normal;
}
.flex.flex-direction-column.gap-3.justify-center.text-center.password-create-acc a {
  cursor: pointer;
}
.Coupons-model .inline-flex.flex-col.w-1\/2.max-w-xl.text-left.align-middle.transition-all.transform.overflow-hidden.rounded-2xl.bg-white.dark\:bg-neutral-900.dark\:border.dark\:border-neutral-700.dark\:text-neutral-100.shadow-xl.h-1\/2 .container.mt-5 {
  padding: 50px 50px !important;
}
.alert.alert-warning {
  background: #ffffff;
  color: #cb2828;
  font-weight: 400;
  font-size: 15px;
}
a.user-link:hover i.text-lg.las.la-user{color:#fff}
.check-out-ui h2 {
  font-size: 30px;
  color: #5F5C5C;
  margin: 20px 0;
  font-weight: 600;
}
.check-out-ui h4 {
  font-size: 20px;
  color: #5F5C5C;
  margin: 20px 0;
  font-weight: 500;
}
.check-out-ui h5 {
  font-size: 18px !important;
  font-weight: 600;
  margin-bottom: 0px; color: #000;
}
.check-out-ui p { 
  margin-bottom: 10px;
}
.check-out-ui .flex.gap-2.mb-1 .text-neutral-500 {
  color: #000;
}
button.btn--delete-cart {
  padding: 0;
  width: 25px;
  height: 25px;
  background: #000;
  color: #fff;
  border: 0;
  position: absolute;
  top: -19px;
  right: -6px;
  font-size: 11px;
}
.show-hide-payment-form-payment h4 {
  font-size: 16px;
  color: #000000;
  margin: 15px 0 5px 0;
  font-weight: 500;
}
.price-details table {
  width: 100%;
}
.line-br {
  width: 100%;
  height: 0.5px;
  background: rgba(var(--c-neutral-200), var(--tw-border-opacity));
  margin: 20px 0;
}
.check-out-ui .flex.gap-2.mb-1 .text-neutral-500, .check-out-ui p, .price-details table td, .price-details table th{
  color: #000;
  font-family: 'Source Sans 3';
  font-size: 14px !important;
}
.font-size-15{font-size: 15px !important;}
.section-add-on h4 {
  margin: 0;
}
.section-add-on a {
  color: #1877F2;
  font-family: 'Source Sans 3'; font-size: 13px;
}
.box-add-on-itime table {
  width: 100%;
}
.box-add-on-itime td.w-15-btn {
  width: 100px;
}
.w-full.renderSidebar-custom .w-15-btn {
  width: 24%;
}
.in-add-on img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
.classNameDetails h6 {
  font-weight: 600;
}
.font-size-22{font-size: 20px;}
.classNameDetails p {
  margin: 0;    font-weight: 500;
  color: #666;
}.classNameDetails {
  display: flex;
  flex-direction: column;
  gap: 0;
  line-height: 19px;
}
.classNameDetails a{color: #1877F2;  font-family: 'Source Sans 3';}
.cart-page-list-page h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
  color: #000;
}
.cart-page-list-page ul li {
  font-size: 14px;
  font-family: 'Source Sans 3';
}
.cart-page-list-page {
  background: #F2F2F2;
  padding: 20px;
  border-radius: 10px;
}
.submet-cart-info h4 {
  font-size: 15px;
  border-bottom: solid 1px #eee;
  margin: 0 0 7px;
  padding: 10px 0;
  color: #5F5C5C;
}
.submet-cart-info p {
  color: #111827;
  font-size: 14px !important;
  line-height: 20px;
}
.submet-cart-info p {
  color: #111827;
  font-size: 14px !important;
  line-height: 20px;
}
.submet-cart-info textarea {
  font-size: 19px;
  font-family: 'Source Sans 3';
  font-weight: 600;
  color: #5F5C5C;
  min-height: 150px;
  padding: 20px;
}
.group-input {
  border: solid 1px #eee;
  width: 100%;
  border-radius: 10px;
  min-height: 45px;
  margin-bottom: 0;
  padding: 10px;
  font-size: 14px !important;
  font-family: 'Work Sans';
  font-weight: 600;
  color: #5F5C5C;
}
.group-type-btn input.group-input {
  border-radius: 10px 0px 0px 10px;
}
.group-type-btn {
  display: flex;
}
.group-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}
.group-type-btn button {
  background: #000;
  color: #fff;
  padding: 0 20px;
  border-radius: 0px 10px 10px 0px;
}
.submet-cart-info label {
  color: #5F5C5C;
  font-size: 13px !important;
  line-height: 18px !important;
  margin-bottom: 20px !important;
  display: inline-block;
  font-weight: 500;
}
.ButtonPrimary-btn {
  display: flex; 
  align-items: center;
  margin-bottom: 25px;
}
.button-check-out-page button {
  border: solid 1px #FFF5AC;
  margin-right: 20px;
  background: #FFF5AC;
  color: #111827;
  min-width: 166px;
}
.ButtonPrimary-btn a {
  color: #1877F2;
  font-size: 13px;font-family: 'Source Sans 3';
}
.content-page-style .nc-Section-Heading h2 {
  line-height: 55px;
}
.flex-border-btn .flow-root {
  border: solid 1px #eee;
  border-radius: 30px;
  padding: 10px 25px;
}
.flex-border-btn .flow-root {
  border: solid 1px #eee;
  border-radius: 30px;
  padding: 10px 25px;
}
.flex-border-btn {
  gap: 20px;
}
.details-page-sub-header {
  margin-bottom: 10px;
}
.w-full.renderSidebar-custom .flex.flex-col.border.border-neutral-200.dark\:border-neutral-700.rounded-3xl {
  border: 0;
  flex-direction: row;width: 100%;
}
.w-full.renderSidebar-custom  .DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2 {
  bottom: 100px !important;
  top: auto !important;
}
.w-full.renderSidebar-custom  .flex.flex-col.space-y-4.payment-detail-part {
  display: none;
}
.w-full.renderSidebar-custom  .top-full {
  top: auto;
  bottom: 69px;
}
.w-full.renderSidebar-custom .alert.alert-success, .w-full.renderSidebar-custom  .alert.alert-danger {
  width: auto;
    font-weight: bold;
    background: #f7f6f6e8;
    max-width: 550px;
    position: absolute;
    top: -45px;
    left: 20%;
    right: 0; 
    margin: auto;
    min-width: 500px;
}
.w-full.renderSidebar-custom .alert.alert-success
{top: -10px;} 
.flex.gallery-list.justify-end.m-0.mr-2\.5.p-0.w-full > div .border-r {
  border: 0;justify-content: space-between;
}
.renderSidebar-custom form.mt-15 {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  justify-content: space-between;
}
.details-page-sub-header h3 {
  color: #5F5C5C;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}
.details-page-sub-header h5 {
  color: #5F5C5C;
  font-size: 17px;
  font-weight: 500;
  border-bottom: solid 1px #eee;
}
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}
.Collapsible__trigger:after {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
  content: "\f107";
  font-family: 'Line Awesome Free';
  font-weight: 900;
}
.Collapsible span.Collapsible__trigger {
  font-family: "Work Sans", sans-serif;
  color: #666;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  border-bottom: solid 1px #eee;
  padding: 0 0 10px;
  margin-bottom: 10px;     position: relative;
}
.bgcolor > div {
  background-color: #F2F2F2;
  padding: 10px;
  border-radius: 20px;
}
.details-page-sub-header.listingSectionCard > div {
  border: 0;
  padding: 0 120px;
}
.details-page-sub-header.listingSectionCard {
  background: #F2F2F2;
  padding: 50px 0; margin: 0;
}
.details-gaps-4 {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.details-gaps-4  .Collapsible span.Collapsible__trigger {
  font-family: "Work Sans", sans-serif;
  color: #5F5C5C;
  font-size: 17px;
  font-weight: 600; 
}
.bg-color-gry {
  background: #f2f2f2;
}
.gallery-list {
  display: flex;
  gap: 5px;
  margin: 50px 0;
}
.gallery-list-headding h3 {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: #5F5C5C;
  text-align: center;
  margin-top: 50px;
}
.mt-mb-50{    margin-top: 50px;}
.list-blog-mb-0 > div {
  margin: 0;
  margin-bottom: 15px;
}
.details-page-sub-header.details-gaps-4 .Collapsible span.Collapsible__trigger { 
  margin: 0;
}
.bgcolor-light{background-color: #F2F2F2;}
.row-profile {
  display: flex;
  flex-wrap: wrap; gap: 10px;
}
.left-user-details {
  width: 350px;
  border-radius: 20px;
  min-height: 500px;
  padding: 35px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 79%, rgba(255, 245, 172, 1) 100%);
}
.right-user-details {
  width: calc(100% - 370px);
  background:#fff;  border-radius: 20px; min-height: 500px;  padding: 35px;
}
.right-user-details-card{
  width: calc(100% - 370px); min-height: 500px; 
}
.right-user-details-card .card{  width:100%;
  background:#fff;  border-radius: 20px;    padding: 35px;
}
.right-user-details-card  .sub-title{color: #000; font-size: 20px;}

.left-user-details .wil-avatar {
  width: 200px;
  height: 200px;
}
.left-user-details .wil-avatar {
  width: 200px;
  height: 200px; 
}
.left-user-details h4 {
  color: #5F5C5C;
  font-size: 27px;
  font-weight: 700;
  text-align: center;
  margin: 20px 0;
}
a.btn.btn-profile {
  border-radius: 50px;
  background: #FFF5AC;
  min-width: 200px;
  padding: 14px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  gap: 5px;
}
.search-promo-code {
  display: flex;
  width: 500px;
  flex-wrap: wrap;
  border: solid 1px #eee;
  padding: 0;
  border-radius: 20px;overflow: hidden;
}
.search-promo-code input {
  padding: 15px;
  width: 87%;
  min-height: 53px;
}
.w-100-max{max-width: 100% !important; flex: 0 0 100% !important;}
.max-1300 {
  width: 1100px !important;
  margin: auto;
}
.min-text-booking {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.min-text-booking .text-part-left {
  max-width: 80%;
  flex: 0 0 80%;
}
.min-text-booking .text-part-left p {
  font-size: 18px;
  font-weight: 500;
  color: #666;
}
.flex-404-page {
  display: flex;
  min-height: 600px;
  align-items: center;
  text-align: left;
  max-width: 80%;
  justify-content: center;
  margin: auto;
}
.flex-404-page h1{ 
  font-size: 40px; font-weight: bold; margin-bottom: 10px; margin-top: 0;
  line-height: 50px;
}
.flex-404-page  p{font-size: 17px; margin-bottom: 20px;}
.flex-404-page  .flex-100{max-width: 60%; flex:0 0 60%}
.sucssus-text-in{color: #08C728; font-weight: 500; margin-bottom: 15px;}
.search-promo-code .focus\:border-primary-300:focus
{border: 0  !important; --tw-ring-color: rgb(255, 255, 255) !important;}
.label-container.label-container-sm-label .label {
  font-size: 13px !important;
  font-weight: 400 !important;
  top: -7px !important;
}
.mid-box-refl h5 {
  font-weight: 600;
  font-family: 'Source Sans 3';
  font-size: 20px;
  margin: 20px;
  color: #5F5C5C;
}
.border-l{border: solid 1px #eee;}
.m-lr{margin-left: auto;
  margin-right: auto;}
.search-promo-code input:focus-visible{border: 0; outline:0}
.search-promo-code span {
  width: 50px;
  height: 50px;
  border: solid 1px #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff5ac;
  border-radius: 100%;
  margin: 2px;
}
.Avatar-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.profile-page-left {
  background: #FFF5AC;
  border-radius: 20px;
  padding: 20px;
  margin-top: 40px;
}
span.icon-uplod input {
  width: 60px;
  position: absolute;
  left: 0;
  height: 24px;
  opacity: 0;
}
.profile-page-left h5 {
  font-size: 15px;
  font-weight: 700;
}
.profile-page-left a {
  font-weight: 600;
}
.nav-left-page li a {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-family: 'Work Sans';
  font-weight: 600;
}
.nav-left-page li a i {
  font-size: 27px;
}
.nav-left-page li a span {
  display: flex;
  gap: 14px;
  align-items: center;
}
.nav-left-page li a:hover, .nav-left-page li a.active{
  background: #fff5ac;
  border-radius: 20px;
}
.nav-left-page ul li {
  margin: 10px 0;
}
.nav-left-page li .line {
  background: #e1e1e1;
  height: 1px;
  margin: 20px 0;
}
.line-mid {
  background: #e1e1e1;
  height: 1px; 
}
.right-user-details h4 {
  font-size: 25px;
  font-weight: 700;
  margin: 0 0 20px 0;
  color: #5F5C5C;
}
.right-user-details-card h4 {
  font-size: 25px;
  font-weight: 700;
  margin: 0 0 20px 0;
  color: #5F5C5C;
}
.mid-list-menu ul {
  display: flex;
  gap: 20px;
}
.mid-list-menu ul a {
  font-weight: 600;
  font-size: 15px;
  display: flex;
  gap: 18px;
  justify-content: center;
  align-items: center;
  color: #111827;
}
.from-edit-click a, button.btn-edit, button.btn-save{color:#1877F2;}
.from-edit-click h5{font-size: 18px; color: #5F5C5C; font-weight: 700;}
.socal-list-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}
.epty-card-text img {
  width: 200px;
}
.epty-card-text {
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.epty-card-text h1 {
  font-weight: 600;
  font-size: 25px;
  margin: 20px 0;
}

.epty-card-text p {
  max-width: 100%;
  font-size: 16px !important;
  margin: 0;
  margin-bottom: 50px;
}
.flex-404-page a {
  border-radius: 9999px !important;
}
.list-socal {
  width: 48%;
  border: solid 1px #707070;
  border-radius: 20px;
  padding: 7px 20px;
}
.list-socal > div {
  display: flex;
  justify-content: space-between;
  gap: 10px; 
}
span.btn-sm-blu-light {
  background: #1877F2;
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 100%;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
span.btn-ch-sm {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF5AC;
  border-radius: 100%;
}

a.bold-black-an {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.hearing-from-content {
  position: absolute;
  top: 0;
  z-index: 9;
  display: flex;
  justify-content: center; 
}
.left-part-hedding {
  max-width: 50%;
  flex: 0 0 50%; padding-left: 80px;
}
.right-part-hedding {
  max-width: 50%;
  flex: 0 0 50%;
}
.left-part-hedding h4 {
  font-size: 45px; 
  line-height: 50px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 25px;
}
.left-part-hedding p {
  font-size: 21px;
  color: #fff;
}
.hearing-form-group {
  background: #000;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 50px;
}
.btn-box-form {
  border-radius: 100% !important;
    height: 68px !important;
    position: absolute !important;
    top: 27px;
    margin: auto;
    left: auto;
    right: auto;
    width: 68px;
    padding: 10px !important;
}
.hearing-form-group h5 {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

.main-about-banner {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-direction: column;
  max-width: 900px;
  margin: auto;
  line-height: 101px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
}

.main-about-banner h1 {
  font-size: 60px;
  font-weight: bold;
  top: 0;
  color: #fff;
  align-items: center;
  text-transform: none;
  font-family: "Work Sans", sans-serif;
  position: static;
  flex: none;
  margin: 0;
  padding: 0; height: auto;
}
.main-about-banner p {
  font-size: 20px;
  color: #fff;
  line-height: 34px;
}.about-box h5 {
  color: #5F5C5C;
  font-weight: bold;
  font-size: 16px;
  margin-top: 100px;
}

.about-box p {
  color: #5F5C5C;
  font-size: 15px;
}
.row-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; gap: 80px;
  margin-top: 30px;
}
.row-card .rond-icon {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  justify-content: center;
  display: flex;
  background: #CCD7E1;
  align-items: center;
}
.text-card-icon {
  font-size: 17px;
  max-width: 200px;
  text-align: center;
  font-weight: bold;
  margin: 15px 0;
}
.nc-SectionGridFilterCard.pb-24.lg\:pb-32 span.block.text-neutral-500.dark\:text-neutral-400.mt-3 {
  text-align: center;
}
.row-office-show-hide {
  display: flex;
  justify-content: start;
  gap: 20px; margin-top: 40px !important;
}
.office-details h1 {
  font-size: 21px;
    margin: 15px 0 7px 0;
    font-weight: 500;
    font-family: 'Source Sans 3';
}
.nc-PageLogin {
  background: url(https://cdn.tinyaway.com/common/2024/09/TA_UIUX_LoginBG1726304993378.png);
  padding-top: 100px;
  min-height: 100%;
  padding-bottom: 100px;
}.mb-0.lg\:mb-0.filter-model .flex.items-center.justify-center.px-4.py-2.text-sm {
  padding: 0;
}
.filter-model  .mb-6.lg\:mb-6 {
  margin: 0;
}
.mb-0.lg\:mb-0.filter-model {
  margin-top: -39px;
  padding: 0;
  background: transparent;
}
.relative.flex.min-h-screen.ml-21.gap-5.mt-10 {
  margin-top: 40px;
}
.nc-ListingStayMapPage.relative .min-h-screen {
  background: #ffffff00; 
}
.play-stay-icon span {
  font-family: 'Work Sans';
  font-weight: 700;
}
span.btn-con {
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 100%;
  background: #fff5ac;
}
.flex.flex-fetures .details-page-sub-header .flex.items-center.space-x-3 {
  flex-direction: column;
  text-align: left;
}
.flex.flex-fetures .details-page-sub-header .flex.items-center.space-x-3 {
  flex-direction: column;
  text-align: left;
  justify-content: start;
  align-items: baseline;
  line-height: normal;
}
.flex.flex-fetures .details-page-sub-header .flex.items-center.space-x-3 p {
  margin-bottom: 10px;
}
.flex.flex-fetures > div {
  width: 50%; 
  overflow: hidden;
}.flex.flex-fetures .flex.flex-direction-column.items-center.justify-start.space-x-3.text-left {
  padding: 0 20px;
}
.flex.flex-fetures > div img {
  border-radius: 20px;
}
.flex.flex-fetures .Collapsible {
  margin-bottom: 10px;
}
.flex.flex-fetures .Collapsible .details-page-sub-header{margin: 0;}
.flex.flex-fetures  .flex.flex-direction-column.items-center.justify-start.space-x-3.text-left {
  padding: 20px;
}
.flex.flex-fetures {
  padding: 50px 0 0;
}

.play-stay-icon {
  display: flex
  ;
      justify-content: start;
      align-items: center;
      grid-gap: 10px;
      grid-gap: 10px;
      gap: 10px;
      padding-top: 7px;
      min-width: 250px;
}
.container.relative.container-serch-form-full .flex.lg\:space-x-4 button.nc-Button.relative.h-auto.inline-flex.items-center.justify-center.rounded-full.transition-colors.text-sm.sm\:text-base.font-medium.px-4.py-2.sm\:px-5.flex.items-center.ttnc-ButtonPrimary.disabled\:bg-opacity-70.bg-primary-6000.hover\:bg-primary-700.text-neutral-50.focus\:outline-none.focus\:ring-2.focus\:ring-offset-2.focus\:ring-primary-6000.dark\:focus\:ring-offset-0 {
  width: auto;
  display: flex
;
  height: 49px;
  margin: auto;
  margin-top: 9px;
  background-color: transparent !important;
  padding: 0;
}
.svg-search-icon-map svg {
  width: 35px;
  height: 21px;
  fill: #eee;
}
.container.relative.container-serch-form-full .flex.lg\:space-x-4 button.nc-Button.relative.h-auto.inline-flex.items-center.justify-center.rounded-full.transition-colors.text-sm.sm\:text-base.font-medium.px-4.py-2.sm\:px-5.flex.items-center.ttnc-ButtonPrimary.disabled\:bg-opacity-70.bg-primary-6000.hover\:bg-primary-700.text-neutral-50.focus\:outline-none.focus\:ring-2.focus\:ring-offset-2.focus\:ring-primary-6000.dark\:focus\:ring-offset-0
span{display: none;}
.container.relative.container-serch-form-full .w-70-1 {
  width: 100%; margin-left: 0;
}
.relative.flex.min-h-screen.ml-21.gap-5.mt-10 span.block.mt-3.font-bold.font-size-16 {
  font-weight: 400;
}
.container.relative.container-serch-form-full  .la-filter:before{display: none;}
.relative.flex.min-h-screen.ml-21.gap-5.mt-10 span.block.mt-3.font-bold.font-size-16  form.m-auto.w-70-1.relative.mt-8.flex.flex-col.md\:flex-row.md\:items-center.rounded-3xl.shadow-xl.dark\:shadow-2xl.bg-white.dark\:bg-neutral-900.divide-y.divide-neutral-200.dark\:divide-neutral-700.md\:divide-y-0.rounded-full {
  margin: 0;
}

.filter-model button.nc-Button {
  background: none !important;
  border: solid 1px transparent;
  color: #dfdfdf !important;
  font-size: 0 !important;
  padding: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.office-details svg {
  width: 50px;
  height: auto;
}
.office-details a {
  text-decoration: underline;
}
.office-details {
  padding: 0 20px;
}
.row-card .col-4 {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 24%;
}
.hedding-aboutus .nc-Section-Heading.relative.flex.flex-col.sm\:flex-row.sm\:items-end.justify-between.mb-12.lg\:mb-16.text-neutral-900.dark\:text-neutral-50 {
  display: none;
}
.clint-logo-about .container{ max-width: 350px !important;}
 .clint-logo-about > .container {
  max-width: 550px !important;
  margin: 0 !important;
  padding: 0 !important; 
} .clint-logo-about .nc-SectionAibnbReviews.hedding-center.sm-hedding-text {
  padding: 0 50px;
  padding-bottom: 50px;
  padding-top: 50px;
}
.contener {
  background: #F2F2F2;
  padding: 100px;
}
.mid-box-B-host {
  max-width: 800px;
  margin: auto;
}
.mid-box-B-host h3 {
  font-size: 24px;
  color: #5F5C5C;
  text-align: center;
  margin: 50px 0;
  margin-top: 0;
}
.mid-box-B-host p {
  text-align: center;
  margin-bottom: 20px;
}
.mid-box-B-host p {
  text-align: center;
  margin-bottom: 20px;
  font-size: 17px;
  line-height: 26px;
}
.w-30 {
  width: 30%;
}
.w-70 {
  width: 70%;
}
.why-become-a-host h4 {
  font-size: 25px;
  color: #5F5C5C;
  font-weight: 500;
  margin: 20px 0;
  margin-top: 0;
}
.why-become-a-host ul li {
  display: flex;
}
.why-become-a-host ul li a {
  font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #111827;
    display: flex
;
    gap: 8px;
    margin-bottom: 14px;
    align-items: center;
}
ul.list-icon-img li a img {
  width: 30px;
  margin-top: -9px;
}
.listing-text-card img {
  width: 30px;
}
.listing-text-card .flex.gap-2 h4 {
  margin: 0;
}

.listing-text-card .flex.gap-2 {
  align-items: center;
}
 
.listing-text-card .flex.gap-2 {
  align-items: center; margin-bottom: 20px;
}
.card-list {
  border-radius: 20px;
  overflow: hidden;
  border: solid 1px #eee;
}
.listing-text-card {
  padding: 20px;
}
.listing-text-card h4 {
  font-family: 'Source Sans 3';
  color: #000000;
  font-size: 23px;
  margin-bottom: 10px;
}
 
.flex-2 {
  display: flex;
  background: #5F5C5C;
}
.flex-2 > div img {
  width: 100%;
}
.contaent-looking-hepl {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
}.contaent-looking-hepl h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  margin: 50px 0;
}
.contaent-looking-hepl p {
  color: #fff;
  font-size: 16px;
}
.icon-set  a{color: #fff;}
.icon-flex {
  display: flex;
  gap: 20px;
  margin: 50px 0;
}.icon-set {
  width: 200px;
}
.icon-set span {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 100%;
  margin: 20px auto;
}
.icon-set span:hover{background: #FFF5AC;}
.icon-set span i {
  font-size: 40px;
  color: #666;
}
p.font-17 {
  font-size: 20px;
}
.card-contact svg, .card-contact img {
  max-width: 300px; 
  height: auto;
}
.card-contact h4 {
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
  font-size: 25px;
}
.card-contact {
  width: 100%;
  min-height: 250px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  gap:12px;
  border: solid 1px #b2b2b2;
}
.office-details img{width: 80px;}
.card-contact h4 {
  font-weight: bold;
  text-align: center;
  margin:20px 0;
  font-size: 25px;
  color: #000;
}
 
h2.page-title {
  color: #5F5C5C;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 17px !important;
}
.min-w-470 {
  min-width: 370px;
}
ul.list-sub-nav {
  border-right: solid 2px #a9a9a9;
}
ul.max-nav-right {
  max-width: 85%;
  margin-left: auto;
  text-align: right;
  border: 0 !important;
}
ul.max-nav-right li a{text-align: right;}
ul.max-nav-right li a {
  text-align: right;
  margin-left: auto;
  margin-right: 0;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  color: #5F5C5C;
}
ul.list-sub-nav {
  border-right: solid 2px #a9a9a9;
  padding-right: 20px;
}
.page-title-all {
  text-align: center;
}.page-title-all h1 {
  font-size: 45px;
  font-weight: bold;
  top: 0;
  color: #5F5C5C;
  align-items: center;
  text-transform: none;
  font-family: "Work Sans", sans-serif;
  position: static;
  flex: none;
  margin: 0;
  padding: 0;
  height: auto;
  line-height: 100px;
}
.blog-banner .main-about-banner {
  max-width: 500px;
}
.blog-banner .main-about-banner p {
  font-size: 30px;
}.card-custom
.mt-8.pr-10.flex.flex-col {
    padding: 0 20px;
    padding-bottom: 20px;
}
.card-custom {
  border-radius: 20px;
  overflow: hidden;
}
.color-light-blu{color: #1877F2;}
.btn-white{background-color: #fff  !important;}
.list-tab-account a {
  padding: 10px 20px;
  border-radius: 50px; font-weight: 500;
}
.content h4 {
  color: #000000;
  font-size: 20px;    margin: 0 0 8px 0;
}
.content p {
  font-weight: 600;
}
.coll-row {
  flex-direction: column;
}
.font-size-20{font-size: 17px !important;}
.list-tab-account {
  display: flex;
  gap: 15px;
}
.list-tab-account a.active {
  background: #fff5ac;
}
.flex.flex-row-add-on img {
  border-radius: 10px; width: 70px; height: 70px;
}
.flex-row-add-on b {
  font-size: 15px;
}
.flex-row-add-on p {
  font-weight: 400;
  font-size: 14px; color: #5F5C5C;
}
.flex.flex-row-add-on .w-100 {
  width: calc(100% - 132px);
  font-weight: bold;
  line-height: 21px;
}.w-100px {
  min-width: 110px;
}
.right-box-add-on {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.right-box-add-on img {
  border-radius: 10px;
}
a.btn.btn-box-css {
  background: #FFF5AC;
  width: 150px;
  margin: 20px auto;
  padding: 5px 10px;
  border-radius: 20px; font-weight: 500;
}

a.btn.btn-box-theme{
  background: #969696;
  width: 150px;
  margin: 0px auto;
  padding: 5px 10px;
  border-radius: 20px;
  color: #000;
  font-weight: 500;}
  .right-box-add-on p {
    font-weight: 500;
    font-size: 15px;
}
.completed{color: #08C728;}

.flex.flex-row-add-on .w-70px {
  width: 70px;
}
.rate-color{
  color: #5F5C5C;
}
.card.card-border-1 {
  width: 30%;
  border: solid 1px #eee;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.block.fixed.bottom-0.inset-x-0.py-4.bg-white.text-neutral-900.border-t.border-neutral-200.z-20 {
  background: #606060;
}
.block.fixed.bottom-0.inset-x-0.py-4.bg-white.text-neutral-900.border-t.border-neutral-200.z-20 span.text-2xl.font-semibold,
.block.fixed.bottom-0.inset-x-0.py-4.bg-white.text-neutral-900.border-t.border-neutral-200.z-20 .text-neutral-500 {
  color: #fff;
}
.star.flex.star-row {
  font-size: 25px;
  text-align: center;
  justify-content: center;
  gap: 20px;
  margin: 0 0 11px 0;
  color: #5F5C5C;
}
.search-wishlist input {
  border: solid 1px #eee;
  border-radius: 20px;
  padding: 10px 20px;
  color: #5F5C5C;
  font-family: 'Source Sans 3';
  font-size: 15px;
  padding-left: 41px !important;
}
.search-wishlist span {
  position: absolute;
  padding: 11px 15px;
  font-weight: bold;
}
.search-wishlist span i{    transform: rotate(251deg);
  font-size: 18px;}
  .card-earn h5 {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    font-family: 'Source Sans 3';
    line-height: 30px;
}
.card-earn {
  max-width: 400px;
  margin: auto;
}
.row-card.card-text .col-4 {
  max-width: 30%;
  flex: 0 0 30%;
}
.row-card.card-text .col-4 .text-card-icon {
  margin: 0 0;
}
.row-card.card-text .col-4 {
  margin: 20px 0;
}
.row-card.card-text .col-4 .text-card-icon .text {
  color: #000;
}
.text-card-icon.text {
  color: #000 !important;
  font-size: 20px;
  font-weight: 700;
}
.card-earn1 {
  max-width: 700px;
  margin: auto;
}
.content.content1 {
  padding-right: 50px;
}
.content1 p {
  font-weight: 500;
  padding-right: 35px;
  color: #666;
}
a.btn.btn-black.big-btn {
  background: #000;
  border-radius: 20px;
  color: #fff;
}

.card-earn p {
  color: #5F5C5C;
  line-height: 25px;
  margin: 20px 0;
}
.big-btn {
  padding: 10px 18px !important;
}
.nav-faq {
  display: flex;
  padding: 20px 10px;max-width: 1200px;
  margin: auto;
}
.menu-faq {
  display: flex;
  justify-content: end;
  width: calc(100% - 100px);    gap: 20px;
}
.menu-faq a {
  border-radius: 20px;
  border: solid 1px #eee;
  padding: 8px 30px;
  color: #606060;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}.nav-w20 h1 {
  font-weight: bold;
  font-size: 35px;
  color: #5F5C5C;
}.nav-w20 {
  width: 100px;
  display: flex;
  align-items: center;
}.menu-faq a:hover {
  background: #fff5ac;
}
.faq-comman-class h2{
font-weight: bold; font-size: 20px; color: #5F5C5C;     margin-bottom: 20px;
}
.faq-comman-class {
  max-width: 1150px;
  margin: auto;
  padding: 50px 0 0;
}
.faq-comman-class .Collapsible span.Collapsible__trigger {
  background: #fff;
  border-radius: 50px;
  display: flex !important;
  align-items: center;
  padding: 10px 20px;     font-size: 15px;
  margin: 0;
}
.faq-comman-class h2 {
  font-weight: bold;
  font-size: 20px;
  color: #5F5C5C;
  margin-bottom: 20px;
}

.faq-comman-class .Collapsible  .Collapsible__contentInner {
  padding: 20px 50px;
}
.faq-comman-class .Collapsible__trigger:after {  font-size: 14px;}
.faq-comman-class  .Collapsible {
  margin-bottom: 15px;
}
.star.flex.star-row svg {
  width: 30px;
  height: auto;
}
.nc-MainNav1.absolute.nav-home1.w-full.z-10.notOnTop.backdrop-filter {
  position: fixed;border-bottom: solid 1px #eee;
} 
.nc-MainNav1.w-full.z-10.notOnTop.backdrop-filter {
  position: fixed;
  top: 0 !important;  border-bottom: none;
} 
.w-full.renderSidebar-custom .w-15-btn {
  width: 85px;
  margin-top: 0px;
}
.bg-primary-6000:focus-visible
{border-color: #fff5ac;}
span.bg-cart-cunt {
  position: absolute;
  background: #fff5ac;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: #000;
  top: -19px;
  right: -16px;
}
.card-procode {
  min-width: 200px;
  border: solid 1px #DBDBDB;
  border-radius: 20px;
  padding: 20px 30px;
  background: #F5F5F5;
}
.card-procode h2 {
  font-size: 20px; margin: 0;
}
.white-color-bg {
  background: #fff;
  border-radius: 20px;
  padding:30px 30px;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 0px;
}
.hr {
  border-bottom: dotted 2px #b0b0b0;
  margin: 10px 0;
}
span.close-promo-code {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
  background: #323030;
  color: #fff;
  border-radius: 100%;
  padding: 1px 5px;
  position: relative;
  top: 1px;
}
.left-box-style-1, .right-box-icons {
  max-width: 50%;
  flex: 0 0 50%;
}
.felx-box-css{color: #5F5C5C;}
.felx-box-css {
  color: #5F5C5C;
  margin: 20px 0;
  text-transform: uppercase;
  font-size: 31px !important;
}
.left-box-style-1 h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}
.left-box-style-1 span.text-sm.text-neutral-500.dark\:text-neutral-400.line-clamp-1.font-bold {
  color: #000;
  font-size: 20px !important;
  text-transform: uppercase;
  margin-bottom: 5px !important;
  font-weight: 600;
}
.mb-bttome{margin-bottom: 20px  !important;}
.right-box-icons h5 {
  font-size: 18px;
  color: #5F5C5C;
  font-weight: 500;
  margin-bottom: 15px;
  border-bottom: solid 1px #eee;
  padding-bottom: 10px;
}
.right-box-icons p {
  color: #111827;
  margin-bottom: 20px;
}
.right-box-icons h6 {
  color: #5F5C5C;
  font-weight: 600;
  margin-bottom: 15px;
}
.right-box-icons {
  padding: 20px 25px;
}
.left-box-style-1 span, .left-box-style-1 svg {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #000; 
}
.left-box-style-1  svg path {
  stroke: #1b1b1b;
}
.icon-share-meaidi{margin-bottom: 15px;}
.mb-0-p{margin: 0 !important;}
.pedding-5{padding: 20px !important; margin-bottom: 15px !important;}
.error-rh {
  background: #FDECEE;
  padding: 20px;
  font-size: 16px;
}
.white-color-bg h4 {
  color: #969696;
  margin: 2px 0;
}
.white-color-bg p {
  font-size: 16px !important;
  color: #969696;
}
.sm-text-lg {
  color: #969696;
  font-size: 16px;
  max-width: 200px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.color-sm-prom {
  color: #1877F2;
  font-size: 17px;
  margin-top: 10px;
}
span.edit-class {
  display: flex;
  justify-content: end;
  margin-bottom: 16px;
  color: #1877F2;
  font-size: 16px;
}
.flex-warp-end-left {
  display: flex;
  justify-content: space-between;
  padding: 0 0px;
}
.left-right-boder-box .hr { 
  height: 2px;border: 0;
  background: repeating-linear-gradient(to right, #bab9b9 0px, #bab9b9 6px, transparent 4px, transparent 10px);
}

.flex-wrop-textend-left {
  display: flex;
  justify-content: space-between;
}
.flex-warp-end-left input {
  width: 25px;
  height: 25px;
  background: #eee;
  border: solid 5px #cbcbcb;
}
.left-right-boder-box:after {
  position: absolute;
  left: 0;
  content: '';
  width: 20px;
  height: 20px;
  background: #f5f5f5;
  top: 0;
  left: -38px;
  border-radius: 100%;
}
.left-right-boder-box:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  background: #f5f5f5;
  top: 0;
  right: -38px;
  border-radius: 100%;
}
.left-right-boder-box{position: relative; margin: 15px 0;}
.neutral-text-loader > div div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 25px 25px;
  margin-top: 0px;
  gap: 10px;
  text-align: center;
}
.neutral-text-loader h2 {
  color: #000;
}
.neutral-text-loader > div svg {
  height: auto; width: 180px;     opacity: 0.8; margin: auto;
}

.neutral-text-loader {
  padding: 50px;
}
.neutral-text-loader h3 {
  font-size: 18px;
  line-height: 30px;
  color: #000;
}
.line-bg{
  background: repeating-linear-gradient(
    45deg,
    #f0f0f0,
    #f0f0f0 10px,
    white 10px,
    white 20px
  );
}
.neutral-text-loader h3 {
  margin-bottom: 40px;
}
.neutral-text-loader > div {
  display: flex;
  background: rgba(255, 255, 255, 0.76); 
  border-radius: 10px; 
  margin-top: 0px;
  padding: 20px;
  flex-direction: column;
}
.flex-col-mid > div {
  max-width: 40%;
}

.flex-col-mid {
  flex-direction: row;
  gap: 0;
  padding: 0 !important;
}
.w-full.renderSidebar-custom .DateRangePicker_picker, .SingleDatePicker_picker {
  top: auto !important;
  margin-top: 0.75rem !important;bottom: 89px;
}

.space-y-0.sm-50-div.mt-0 {
  margin: 0;
}
.nc-ListingStayMapPage.relative.bg-color-gry .container.relative.container-serch-form-full {
  top: 100px;
  z-index: 1;
  background: #f1f1f1;
} 
nav.nc-Pagination.inline-flex.space-x-1.text-base.font-medium {
  margin-bottom: 20px;
}
.nc-ListingStayMapPage.relative .container {
  max-width: 100% !important;
  position: sticky;
}
.nc-ListingStayMapPage.relative .container .flex.justify-start.pt-12 {
  padding-top: 0;
  padding: 18px 0;
}
.container-map-stiky .xl\:h-\[calc\(100vh-88px\)\] {
  height: calc(100vh - 240px);
  border-radius: 20px;
  top: 231px !important;
  margin-bottom: 20px;
}
.mb-0.lg\:mb-0.filter-model {
  position: sticky;
  top: 192px;
  z-index: 999;
  background: #f1f1f1;
}
.nc-ListingStayMapPage.relative.bg-color-gry .nc-BgGlassmorphism.absolute.inset-x-0.md\:top-10.xl\:top-40.min-h-0.pl-20.py-24.flex.overflow-hidden.z-0 {
  display: none;
}
span.icon-uplod {
  position: absolute;
  bottom: -11px;
  background: #fff;
  padding: 1px 9px;
  border-radius: 10px;
  box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.14);
  left: 0;
  right: 0;
  margin: auto;
  width: 60px;
}
.felx.felx-grid {
  display: flex;
  gap: 10px;
}
.left-user-details
{
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  background: #ffffff url("https://pictures.tinyaway.com/filters:format(svg)/200x200/icons/user-profile-bg.png");
}


.reward-card {
  background: #000;
  color: white;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: left;
  position: relative;
}

.reward-card h3 {
  margin: 0;
  font-size: 12px;
  opacity: 0.8;
}

.reward-points {
  font-size: 32px;
  font-weight: bold;
  position: absolute;
  right: 15px;
  top: 10px;
}

.reward-card .level {
  display: inline-flex;
  align-items: center;
  background: #fff;
  color: #F05526;
  padding: 3px 8px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
}

.reward-card .progress-bar {
  width: 100%;
  height: 6px;
  background: #fff;
  border-radius: 5px;
  margin: 8px 0;
  position: relative;
  overflow: hidden;
}

.reward-card .progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #F05526, #F05526);
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}

/* Progress Width Classes */
.reward-card .progress-10 { width: 10%; }
.reward-card .progress-20 { width: 20%; }
.reward-card .progress-30 { width: 30%; }
.reward-card .progress-40 { width: 40%; }
.reward-card .progress-50 { width: 50%; }
.reward-card .progress-60 { width: 60%; }
.reward-card .progress-70 { width: 70%; }
.reward-card .progress-80 { width: 80%; }
.reward-card .progress-90 { width: 90%; }
.reward-card .progress-100 { width: 100%; }

.reward-card .progress-text {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  opacity: 0.8;
}
span.block.text-neutral-400.bg-color-search-icon {
  min-width: 48px;
}


     .button-loader {
            font-size:0 !important;
            pointer-events: none; /* Disable clicking while loading */
        }

        /* Loader Animation */
        .button-loader::before,
        .button-loader::after {
            content: "";
            width: 8px;
            height: 8px;
            background-color: black;
            border-radius: 50%;
            animation: blink 1.5s infinite; 
        }

        .button-loader::before { animation-delay: 0s; margin-right: 5px; }
        .button-loader::after { animation-delay: 0.4s; }
        button.inactive {
          background-color: #fff !important;
          border: solid 1px #eee;
      }
        @keyframes blink {
            0%, 100% { opacity: 0.3; }
            50% { opacity: 1; }
        }
        .model-price-filters .px-10.divide-y {
          padding: 0;
      }
      .model-price-filters .px-10.divide-y {
        padding: 0;
    }
    .model-price-filters .py-7 {
      padding: 1rem;
  }
  .model-price-filters .relative.flex.flex-col.px-5.py-6.space-y-8 {
    padding: 0;
}
.model-price-filters .relative.flex.flex-col.px-5.py-6.space-y-8 .rc-slider.text-red-400 {
  margin-left: 9px;
  margin-top: 7px;
}
.model-price-filters h3.text-xl.font-medium,
.model-price-filters label, .model-price-filters span.font-medium.text-neutral-800.dark\:text-neutral-200 span.text-neutral-900.dark\:text-neutral-100 {
  font-size: 14px;
  text-transform: uppercase;
  color: #606060;
  font-weight: 400;
}

.model-price-filters  .relative.flex.px-5.py-6.space-y-5 {
  align-items: center;
  gap: 45px; margin: 0; padding: 3px;
}
.rc-slider-track {
  --tw-bg-opacity: 1;
  background-color: #d9cc66 ;
}
.rc-slider-handle{border-color:#d9cc66 }
.model-price-filters  .relative.flex.px-5.py-6.space-y-5 > div{padding: 0; margin: 0;}
.filters-color-btn{background-color: #666;
  color: #fff;}
 
  .model-price-filters label, .model-price-filters span.font-medium.text-neutral-800.dark\:text-neutral-200 {
    text-transform: uppercase;
    color: #606060;
    font-weight: 400;
    margin-left: 5px;
    font-family: 'Source Sans 3';
    font-size: 13px;
}
.max-w-4xl-4 {
  max-width: 45rem;
}
.model-price-filters button.w-8.h-8.rounded-full.flex.items-center.justify-center.border.border-neutral-400.dark\:border-neutral-500.bg-white.dark\:bg-neutral-900.focus\:outline-none.hover\:border-neutral-700.disabled\:hover\:border-neutral-400.dark\:disabled\:hover\:border-neutral-500.disabled\:opacity-50.disabled\:cursor-default {
  background-color: #606060;
    color: #fff;
    width: 30px;
    height: 30px;
}

.model-price-filters button.w-8.h-8.rounded-full.flex.items-center.justify-center.border.border-neutral-400.dark\:border-neutral-500.bg-white.dark\:bg-neutral-900.focus\:outline-none.hover\:border-neutral-700.disabled\:hover\:border-neutral-400.dark\:disabled\:hover\:border-neutral-500.disabled\:opacity-50.disabled\:cursor-default a.px-4.py-2.sm\:px-5 {
    display: none;
}

.model-price-filters .h-6.w-6
{
  width: 20px;
  height: 20px;
  margin: 3px;
  border-radius: 0;
  border-color: #707070;

 }
 .model-price-filters  .p-4.flex-shrink-0.bg-neutral-50.dark\:bg-neutral-900.dark\:border-t.dark\:border-neutral-800.flex.items-center.justify-between {
    background: none;
    justify-content: end;
}
.model-price-filters button.nc-Button.relative.h-auto.inline-flex.items-center.justify-center.rounded-full.transition-colors.text-sm.sm\:text-base.font-medium.px-4.py-2.sm\:px-5.ttnc-ButtonThird.text-neutral-700.border.border-neutral-200.dark\:text-neutral-200.dark\:border-neutral-700.focus\:outline-none.focus\:ring-2.focus\:ring-offset-2.focus\:ring-primary-6000.dark\:focus\:ring-offset-0 {
    text-transform: uppercase;
    border: 0;
}
 .model-price-filters .nc-NcInputNumber.flex.items-center.justify-between.w-2\/100.justify-start .flex.flex-col {
  width: 200px;
}
.model-price-filters  .w-28 {
  width: 13rem;
}
.model-price-filters .text-neutral-900 {
  --tw-text-opacity: 1;
  color: #707070;
}
.model-price-filters  button.nc-Button.relative.h-auto.inline-flex.items-center.justify-center.rounded-full.transition-colors.text-sm.sm\:text-base.font-medium.px-4.py-2.sm\:px-5.ttnc-ButtonThird.text-neutral-700.border.border-neutral-200.dark\:text-neutral-200.dark\:border-neutral-700.focus\:outline-none.focus\:ring-2.focus\:ring-offset-2.focus\:ring-primary-6000.dark\:focus\:ring-offset-0 {
  border: 0;
  text-transform: uppercase;
}
.model-price-filters .py-7 {
  padding: 1rem;
  border-color: #afafaf !important;
  margin: 7px 10px;
}
.model-price-filters .relative.flex.flex-col.px-5.py-6.space-y-8 .rc-slider.text-red-400 {
  margin-left: 12px;
  margin-top: 7px;
}
.container-search-left form.m-auto.w-70-1.relative.mt-8.flex.flex-col.md\:flex-row.md\:items-center.rounded-3xl.shadow-xl.dark\:shadow-2xl.bg-white.dark\:bg-neutral-900.divide-y.divide-neutral-200.dark\:divide-neutral-700.md\:divide-y-0.rounded-full {
  margin: 30px 0 4px;
}
.max-width-200 {
  top: 89px;
}
span.icon-text-popup img {
  width: 70px;
  margin: auto;
  margin-bottom: 5px;
}
span.icon-text-popup span {
  width: 70px;
  margin: auto;
  margin-bottom: 5px;
}
.rounded-model-ui span {
  font-weight: 400; text-transform: uppercase; font-size: 12px;
}
.rounded-model-ui {
  align-items: baseline;
}
.search-left-part form.m-auto {
  margin-left: 36px;
}
[type='checkbox']:checked, [type='radio']:checked,
[type='checkbox']:checked:hover, [type='checkbox']:checked:focus, [type='radio']:checked:hover, [type='radio']:checked:focus { 
  background-color: #fff5ac  !important; 
}
.focus\:ring-primary-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: none !important;
}

.regervesonPagenesonFull-page .right-user-details {
  width: 100%;
}
.regervesonPagenesonFull-page {
  width: calc(100% - 360px);
}
.mt-5.mb-5.from-edit-click .flex.justify-between {
  align-items: flex-start;
}
.min-h-screen.text-center .h-full {
  height: auto;
  min-height: auto;
  overflow: auto;
}
/* Skeleton Loader Styles */
.skeleton-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  max-width: 100%; 
  border-radius: 8px;
}

.skeleton-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skeleton-title {
  width: 60%;
  height: 24px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-subtitle {
  width: 40%;
  height: 16px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skeleton-icon-text {
  width: 50%;
  height: 16px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-content {
  display: flex;
  justify-content: space-between;
}

.skeleton-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skeleton-section-title {
  width: 30%;
  height: 20px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-text {
  width: 80%;
  height: 14px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-image-container {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.skeleton-image {
  width: 150px;
  height: 100px;
  background: #e0e0e0;
  border-radius: 8px;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-actions {
  display: flex;
  gap: 12px;
  align-items: center;
}

.skeleton-button {
  width: 120px;
  height: 36px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-stars {
  width: 100px;
  height: 20px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-cancel-button {
  width: 150px;
  height: 36px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

/* New Loader 200x200 with border-radius 100% */
.skeleton-loader-circle {
  width: 200px;
  height: 200px;
  background: #e0e0e0;
  border-radius: 100%;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #d6d6d6;
  }
  100% {
    background-color: #e0e0e0;
  }
}


.icon-set-filtter {
  display: flex
;
}

.icon-set-filtter button svg {
  width: 25px;
  height: auto;
}
.icon-set-filtter button {
  background-color: transparent !important;
  padding: 0;
}
.icon-set-filtter {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.icon-set-filtter button svg {
  width: 17px !important;
  height: auto;
  fill: #eee !important;
  margin: 0;
}
.icon-set-filtter button {
  background-color: transparent !important;
  padding: 0;
}

.icon-set-filtter button svg path {
  fill: #676262;
}
.icon-set-filtter button.class-active-fillter svg path{fill: #000;}
.focus\:ring-primary-6000:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: none !important;
}
.pay-with-main .group-box h4 {
  font-size: 16px !important;
  margin: 0;
}
.w-full.lg\:w-3\/5.sm-full-100.xl\:w-2\/3.space-y-5.lg\:space-y-5.lg\:pr-5 .relative.grid.grid-cols-4.sm\:grid-cols-1.gap-5.sm\:gap-2.mt-15.mb-15.sm-grid-cols-1 {
  margin: 0 !important;
}
.grid-gap-15 > div[role="tablist"] {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.grid-gap-15 button {
  width: 100%; 
  max-width: 23%;
}

.right-user-details-card .white-color-bg h4 {
  color: #606060;
  margin: 2px 0;
  font-size: 15px;
}
.right-user-details-card .glide__track ul.glide__slides li {
  overflow: hidden;
}
.right-user-details-card  .white-color-bg p {
  font-size: 15px !important;
  color: #969696;
}
.right-user-details-card  .left-right-boder-box {
  position: relative;
  margin: 0;
  margin-bottom: 12px;
}
.right-user-details-card  .left-right-boder-box:before {
  position: absolute;
  content: '';
  width: 25px;
  height: 25px;
  background: #ffff;
  top: 0;
  right: -38px;
  border-radius: 100%;
  border: solid 1px #ddd;
}
.right-user-details-card  .left-right-boder-box .hr {
  height: 2px;
  border: 0;
  background: repeating-linear-gradient(to right, #ddd 0px, #ddd 6px, transparent 4px, transparent 10px);
  margin: 0;
  margin-top: 12px;
}
.right-user-details-card  .left-right-boder-box:after {
  position: absolute;
  left: 0;
  content: '';
  width: 25px;
  height: 25px;
  background: #fff;
  top: 0;
  left: -38px;
  border-radius: 100%;
  border: solid 1px #ddd;
}
.right-user-details-card  .color-sm-prom {
  color: #000000;
  font-size: 15px;
  margin-top: 0;
}
.right-user-details-card  .white-color-bg {
  background: #fff;
  border-radius: 20px;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 0px;
  border: solid 1px #ddd;
  margin-bottom: 15px;
}
.right-user-details-card .sm-text-lg {
  color: #969696;
  font-size: 16px;
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}
.right-user-details-card .sm-text-lg {
  color: #969696;
  font-size: 15px;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 5px;
}
.right-user-details-cardi.text-lg.las.la-info-circle.pt-3 {
  padding-top: 2px;
}
.nc-MainNav1.absolute.nav-home1.w-full.z-10.onTop {
  background: none;
}
.nc-MainNav1.w-full.z-10.onTop {
  background: #000;
}
.footer-header {
  display: flex; 
  align-items: center;
  gap: 30px;
  padding-bottom: 25px;
  flex-wrap: wrap;
}

.line-footer {
  width: 1px;
  height: 70px;
  background: #808080;
}

.logo-footer {
  max-width: 54%;
  flex: 0 0 54%;
}

.text-footer-top {
  max-width: 40%;
  flex: 0 0 40%;
  padding-left: 50px;
}

.footer-column:nth-of-type(1),
.footer-column:nth-of-type(2) {
    width: 30%;
}

.footer-column:nth-of-type(3) {
    width: 40%;
}

.footer-column h3 {
  font-size: 20px !important;
  text-transform: uppercase;
  color: #e5e7eb;
  margin-bottom: 20px;
  font-weight: 600;
}

footer.footer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  border-top: solid 1px #808080;
  margin-top: 10px;
}

.footer-column ul li {
  line-height: 35px;
}

.bg-black.nc-Footer.relative.lg\:py-10.lg\:pb-0.border-t.border-neutral-200.dark\:border-neutral-700 {
  padding-bottom: 30px !important;
}

.footer-all-right-reserved {
  color: #fff;
}

.social-icons {
  display: flex;
  gap: 20px;
  margin: 25px 0;
}

.social-icons a {
  background: #fff;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  color: #000;
  font-size: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons a i {
  font-size: 25px;
}
.social-icons a:hover{background: #ffe69c;}
.social-icons a:hover i{color: #000;}
/* Responsive Design */
.mb-10.reward-card{margin-bottom: 20px;}
.mb-10.reward-card + p {
  font-size: 15px;
  color: #005f15;
}
.nav-menu {
  direction: rtl;
  text-align: right;
}
.direction-end {
  direction: rtl;
  text-align: right;
}
.direction-end li ul {
  border-right: solid 2px #938c8c;
  padding-right: 15px;
  margin-right: 10px;
}

.direction-end li ul ul {
  margin-right: 15px;
  border-color: #938c8c;
}
.direction-end + div {
  border: 0 !important;
}
.direction-end .text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(118, 117, 117);
}
.direction-end svg {
  fill: #000;
  background: #fff5ac;
  border-radius: 5px;
}
 

.cart-page-mid-text {
  text-align: center;
  padding: 100px 0;
  max-width: 750px;
  margin: auto;
}
.cart-page-mid-text h4 {
  font-weight: 600;
  font-size: 24px;
  color: #5F5C5C;
  line-height: 33px;
  margin-bottom: 20px;
}
.cart-page-mid-text p {
  color: #111827;
  font-size: 16px;
}
.flex.gallery-list.justify-end.m-0.mr-2\.5.p-0.w-full>div {
  background: #fff;
  width: 193px;
  max-width: 38%;
  border-radius: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  height: 51px;
  padding: 0 20px;
}
.w-full.renderSidebar-custom .w-15-btn {
  width: 200px;
  margin-top: 0;
}
@media (max-width: 1024px) {
  .footer-header {
    flex-direction: column;
    text-align: center;
  }

  .logo-footer {
    max-width: 100%;
    flex: 1 1 100%;
  }

  .text-footer-top {
    max-width: 100%;
    flex: 1 1 100%;
    padding-left: 0;
  }

  .footer-column:nth-of-type(1),
  .footer-column:nth-of-type(2),
  .footer-column:nth-of-type(3) {
    width: 100%;
    text-align: center; margin-bottom: 20px;
  }

  .social-icons {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .tw-banner img {
    min-height: 300px;
}

  .footer-header {
    gap: 15px;
  }

  .footer-column h3 {
    font-size: 18px;
  }

  .footer-column ul li {
    line-height: 30px;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
  }

  .social-icons a i {
    font-size: 20px;
  }

  
  .footer-column:nth-of-type(1),
  .footer-column:nth-of-type(2){
    width: 50%;
    text-align: center;
  }

   
  .footer-column:nth-of-type(3) {
    width: 100%;
    text-align: center;
  }

}

@media (max-width: 480px) {
  .footer-header {
    padding-bottom: 15px;
  }

  .footer-column h3 {
    font-size: 16px;
  }

  .footer-column ul li {
    line-height: 25px;
  }

  .social-icons {
    gap: 15px;
  }

  .social-icons a {
    width: 28px;
    height: 28px;
  }

  .social-icons a i {
    font-size: 18px;
  }
}












@media (max-width: 1440px) {
  .nc-MainNav1.w-full.z-10.onTop .container {
      max-width: 100% !important;
  }
}
@media (min-width: 1536px){
  
  .container {
      max-width: 100% !important; padding: 0 120px !important;
  } 
  }
 
  @media (max-width: 1236px){
  .nc-SectionGridSimilarListing h2, .nc-Section-Heading h2 {    font-size: 35px;}
  }
  @media (max-width: 1036px){
    .z-10.mb-12.lg\:mb-0.lg\:-mt-40.w-full.formsearchtop .text-3xl.md\:text-4xl.font-semibold, .mt-2.md\:mt-3.font-normal.white.text-center.sm\:text-xl.text-neutral-500.dark\:text-neutral-400 {
      color: #000;
      width: 100%;
  }
  .class-SectionAibnbReviews {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #969696;
    flex-wrap: wrap;
    flex-direction: column;
}
.class-SectionAibnbReviews > div {
  width: 100%;
  padding: 25px;
}
.bg-black.nc-Footer.relative.lg\:py-10.lg\:pb-0.border-t.border-neutral-200.dark\:border-neutral-700 {
  padding-top: 50px;
}
    .about-box img.rounded-2xl.mt-24.mb-24 {
      margin: 20px 0;
  }
    .about-box.mb-24 .flex.gap-8.mt-10 {
      flex-wrap: wrap;
  }
  .about-box .flex.gap-8.mt-10 {
    flex-wrap: wrap;
}
.about-box .flex.gap-8.mt-10 .w-1\/2 {
  width: 100%;
}
.main-about-banner h1 {
  font-size: 40px; 
}
.main-about-banner p {
  font-size: 15px;
  color: #fff;
  line-height: 20px;
}
.about-box .flex.gap-8.mt-10 img.rounded-2xl.mt-24.mb-24 {
  margin: 0 !important;
} 
.hearing-from-content.container {
  flex-wrap: wrap;
  flex-direction: column;
}
.left-part-hedding {
  max-width: 100%;
  flex: 0 0 100%;
  padding-left: 0px;margin-top: 0;
}
.right-part-hedding {
  max-width: 100%;
  flex: 0 0 100%;
}
.left-part-hedding h4 {
  font-size: 30px;
  line-height: 37px;
  font-weight: bold;
  color: #fff;
  margin-top: 25px;margin-bottom: 0;
}
.hearing-from-content {
  position: relative;
  top: -172px; 
}
.btn-box-form {
  border-radius: 100% !important;
  height: 68px !important;
  position: absolute !important;
  top: -36px;
  margin: auto;
  left: auto;
  right: auto;
  width: 68px;
}.about-box.mb-24 img.rounded-2xl {
  width: 100%;
}
.contener {
  background: #F2F2F2;
  padding: 20px;
}
.why-become-a-host > div {
  flex-direction: column;
}
.why-become-a-host > div > div{
  width:100%
}
    .flex-full-sm {
      flex-wrap: wrap;
  }
    .ng-width-940 .flex-shrink-0.mb-10.lg\:mb-0.lg\:mr-10.lg\:w-2\/5 {
      padding: 32px 64px;
  }
    .flex-full {
      flex-wrap: wrap;
  }
  .flex-full > div{width: 100%  !important;}
  .flex.gap-8.flex-full-sm {
    flex-wrap: wrap;
}
.left-user-details {
  width: 250px; 
}
.right-user-details {
  width: calc(100% - 260px) !important; 
} 
.bgcolor-light.pb-14.pt-10 .container {
  max-width: 100%;
}
.list-socal {
  width: 100%;
}
}

@media (max-width: 768px){
  .about-page-content .flex-2 {
    flex-direction: column;
}
.contaent-looking-hepl { 
  width: 100%;
}
  .flex.flex-login.mt-4 {
    flex-direction: column;
}
.nc-PageLogin { 
  padding-top: 20px;
  min-height: 100%;
  padding-bottom: 20px;
}
.flex.flex-login.mt-4 .w-1\/2 {
  width: 100%;
}
  .grid-gap-15 button {
    width: 100%; 
    max-width: 45%;
  }

  .model-price-filters .relative.flex.px-5.py-6.space-y-5 {
    align-items: start;
    grid-gap: 45px;
    gap: 10px;
    margin: 0;
    padding: 3px;
    flex-direction: column;
}
.nc-NcInputNumber.flex.items-center.justify-between.w-2\/100.justify-start .flex.flex-col {
  min-width: 100px;
}
.model-price-filters .w-28 {
  width: 13rem;
  gap: 10px;
}
  .nc-ListingStayMapPage.relative.bg-color-gry .container.relative.container-serch-form-full,
  .mb-0.lg\:mb-0.filter-model {
 
    position: static;
}

  .nc-ListingStayMapPage.relative .min-h-screen {
    background: #ffffff00;
    width: 100%;
}
.nc-HeroSearchForm.w-full.max-w-6xl.py-5.lg\:py-0 {
  background: #fff;
  margin-top: -6px;
  border: solid 1px #666 !important;
}
  .container.relative.container-serch-form-full .flex.justify-start.pt-12 {
    flex-direction: column;
    gap: 10px;
}
.play-stay-icon {
  min-width: 100%;
}
.m-0.w-5\/6.search-left-part {
  width: 100%;
}
  .felx-box-css {
    font-size: 23px !important;
}
  .left-box-style-1 span, .left-box-style-1 svg {
    -webkit-line-clamp: 3;
}
  .left-box-style-1 span.text-sm.text-neutral-500.dark\:text-neutral-400.line-clamp-1.font-bold {

    -webkit-line-clamp: 3;
}
  .right-box-icons {
    padding: 20px 0;
}
  .nc-PayPage .w-full.flex.mb-bttome {
    flex-direction: column;
}
.left-box-style-1, .right-box-icons {
  max-width: 100%;
  flex: 0 0 100%;
}
  .right-user-details-card {
    width:100%;
}
  .mid-list-menu ul { 
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

  .list-socal {
    width: 100%; 
}
.left-user-details {
  width: 100%; 
}
.right-user-details {
  width: calc(100% - 0px) !important; 
} 
    .none-phone {
      display: none !important;
  }
  .list-tab-account {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    flex-direction: column;
}
.right-user-details > .flex {
  flex-direction: column;
}
.right-user-details > .flex > div{
  width: 100%;
}
.page-title-all h1 {
  font-size: 24px; 
}

form.m-auto.w-70-1.relative.mt-8.flex.flex-col.md\:flex-row.md\:items-center.rounded-3xl.shadow-xl.dark\:shadow-2xl.bg-white.dark\:bg-neutral-900.divide-y.divide-neutral-200.dark\:divide-neutral-700.md\:divide-y-0.rounded-full {
  border-radius: 0;
}

.justify-center.h-14.border-r.relative.flex.flex-1.\[.nc-hero-field-padding.\].flex-shrink-0.items-center.space-x-3.cursor-pointer {
  width: 100%;
  justify-content: space-between;
}
.flex.flex-col.lg\:flex-row.lg\:items-center.w-full.flex-shrink-0.relative.divide-y.divide-neutral-200.dark\:divide-neutral-700.lg\:divide-y-0.md\:border-l.md\:border-r.border-neutral-200.lg\:border-none {
    padding: 0;
    margin: 0;
}
.px-0 {
  display: flex;
  justify-content: center;
  padding: 10px;
}
button.nc-Button.relative.h-auto.inline-flex.items-center.justify-center.rounded-full.transition-colors.text-sm.sm\:text-base.font-medium.px-4.py-3.sm\:px-6.uppercase.w-15-btn.bg-primary-6000.hover\:bg-primary-700.flex.items-center.justify-center.text-neutral-50.focus\:outline-none.py-4.rounded-l.h-14.focus\:outline-none.focus\:ring-2.focus\:ring-offset-2.focus\:ring-primary-6000.dark\:focus\:ring-offset-0 {
  border-radius: 10px !important;
}
.container.flex.items-center.justify-between {
  flex-direction: column;
}
.renderSidebar-custom form.mt-15 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.renderSidebar-custom form.mt-15 .flex.gallery-list.justify-end.m-0.mr-2\.5.p-0.w-full {
  justify-content: center;
}.w-full.renderSidebar-custom .w-15-btn {
  width: 70px;
  margin-top: 10px; 
}
.w-full.renderSidebar-custom .top-full {
  top: auto;
  bottom: 69px;
  width: 250px;
}

.w-full.renderSidebar-custom div#headlessui-popover-panel-23 {
  width: 250px;
}
.w-full.renderSidebar-custom .renderSidebar-custom form.mt-15 .flex.gallery-list.justify-end.m-0.mr-2\.5.p-0.w-full
.relative.flex-shrink-0.flex.z-10.\[.lg\:nc-flex-2.\].border-end.space-x-3 {
  width: 141px;
}

.flex.gallery-list.justify-end.m-0.mr-2\.5.p-0.w-full > div .border-r .font-semibold {
  font-weight: 600; 
  line-height: 2;
  max-width: 80px;
}
.w-full.renderSidebar-custom {
  justify-content: center;
}
.nc-CheckOutPage main.container.mt-11.mb-24.lg\:mb-32 .flex-col-reverse {
  flex-direction: column;
}

.check-out-ui h2 {
  font-size: 22px;
  color: #5F5C5C;
  margin: 0px 0;
  font-weight: 600;
}
.check-out-ui .pedding-5.w-full.flex.flex-col.sm\:rounded-2xl.sm\:border.border-neutral-200.dark\:border-neutral-700.space-y-6.sm\:space-y-4.px-0.sm\:p-4.xl\:p-4.p-4 {
  padding: 0 !important;
  margin-top: 10px;
  border: solid 1px #eee;
  padding: 10px !important;
  border-radius: 10px;
  position: relative;
}
.check-out-ui  .w-full.w-1\/2 h4 {
  display: none;
}
.check-out-ui  span.edit-class {
  position: absolute;
  top: 7px;
  right: 7px;
}
.mb-10.mid-box-refl.mt-100.text-center.w-1\/2.m-lr .text-center.mt-5 {
  display: flex;
  flex-direction: column;
}
.search-promo-code {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  border: solid 1px #eee;
  padding: 0;
  border-radius: 20px;
  overflow: hidden;
}
.regervesonPagenesonFull-page .right-user-details .line-mid.mt-5.mb-5 +  .flex {
  flex-direction: column;
}
.right-user-details .w-70, .right-user-details .w-30 {
  width: 100%;
}
.right-user-details  .flex.gap-20.table-row-2 {
  gap: 2rem;
}
  }



  @media (max-width: 768px){
    .container.relative.container-serch-form-full .flex.lg\:space-x-4 .hidden.lg\:flex.space-x-4 {
      display: block;
  }
    .container.relative.container-serch-form-full .flex.lg\:space-x-4 {
      position: absolute;
      top: 13px;
      z-index: 9;
      right: 59px;
  }
  .container.relative.container-serch-form-full .icon-set-filtter {
    position: absolute;
    right: 15px;
    top: 34px;
}
.container.relative.container-serch-form-full .flex.justify-start.pt-12 {
  position: relative;
}
.m-0.w-5\/6.search-left-part {
  min-height: 103px;
}

    .nc-CheckOutPage .pedding-5.w-full.flex.flex-col.sm\:rounded-2xl.sm\:border.border-neutral-200.dark\:border-neutral-700.space-y-6.sm\:space-y-4.px-0.sm\:p-4.xl\:p-4.p-4 .flex.flex-direction-column.justify-between .flex {
      flex-direction: column;
  }
  .nc-CheckOutPage .pedding-5.w-full.flex.flex-col.sm\:rounded-2xl.sm\:border.border-neutral-200.dark\:border-neutral-700.space-y-6.sm\:space-y-4.px-0.sm\:p-4.xl\:p-4.p-4 .flex.flex-direction-column.justify-between .flex 
    .py-0.px-0.space-y-0.w-5\/6
    { flex-direction: column;}
    .nc-CheckOutPage .pedding-5.w-full.flex.flex-col.sm\:rounded-2xl.sm\:border.border-neutral-200.dark\:border-neutral-700.space-y-6.sm\:space-y-4.px-0.sm\:p-4.xl\:p-4.p-4 .flex.flex-direction-column.justify-between .flex .flex.gap-2.mb-1 {
      flex-direction: row;
  }
    .flex.flex-border-btn.felx-box-css {
      flex-direction: column;
  }
    .flex-warp-end-left { 
      flex-direction: column;
  }
    .white-color-bg h4 { 
      font-size: 14px;
  }
    .nc-ListingExperiencesDetailPage .w-30 .list-icon-linstin-page {
      flex-direction: row;
      margin-bottom: 11px;
      gap: 20px;
      justify-content: center;
  }

    .nc-ListingExperiencesDetailPage .w-30,     .nc-ListingExperiencesDetailPage .w-70{
      width: 100%;
  }
    .nc-ListingExperiencesDetailPage .flex {
      flex-direction: column;
  }
    .title_left_flex {
      max-width: calc(100% - 50px);
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100% - 50px);
      padding-left: 13px;
      text-align: center;
  }

    .nc-CheckOutPage .w-full.lg\:block.flex-grow.w-1\/2 {
      width: 100% !important;
  }
  .nc-CheckOutPage  .w-full.w-1\/2 {
    width: 100%;
}
    .regervesonPagenesonFull-page {
      width: calc(100% - 10px);
  }
    .right-user-details-card .mb-10.mid-box-refl.mt-100.text-center.w-1\/2.m-lr {
      width: 100%;
  }
    .menu-faq {
      display: flex;
      justify-content: start;
      width: calc(100% - 0px);
      grid-gap: 20px;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 20px;
  }
    .nav-faq {
      display: flex;
      padding: 10px 10px; 
      flex-direction: column;
  }

    .details-page-sub-header.listingSectionCard > div {
      border: 0;
      padding: 0 20px;
  }

    .details-page-sub-header.listingDescription__wrap .Collapsible__contentInner p {
      margin-top: 10px;
      line-height: 18px;
  }
    .renderSidebar-custom form.mt-15 .flex.gallery-list.justify-end.m-0.mr-2\.5.p-0.w-full {
      justify-content: center;
      flex-direction: row;
  }
  .flex.gallery-list.justify-end.m-0.mr-2\.5.p-0.w-full > div {
    background: #fff;
    width: 42%;
    max-width: 100%;
    border-radius: 50px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 51px;
    padding: 0 10px;
    line-height: 17px;
}
.flex.gallery-list.justify-end.m-0.mr-2\.5.p-0.w-full > div:nth-of-type(1) {
  width: 60%;
}
    .w-full.renderSidebar-custom .alert.alert-success, .w-full.renderSidebar-custom .alert.alert-danger {
      width: auto;
      font-weight: bold;
      background: #f7f6f6e8;
      max-width: 100%;
      position: absolute;
      top: -45px;
      left: 0;
      right: 0;
      margin: auto;
      min-width: 100%;
  } 
  .nc-ListingExperiencesDetailPage .\!space-y-6 > .flex, .nc-ListingExperiencesDetailPage .\!space-y-6 > .flex > div {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
    width: 100%;
}

.nc-ListingExperiencesDetailPage .wil-avatar.relative.flex-shrink-0.inline-flex.items-center.justify-center.text-neutral-100.uppercase.font-semibold.shadow-inner.rounded-full.h-28.w-28.ring-1.ring-white.dark\:ring-neutral-900 {
  flex: none;
  width: 117px;
}
.absolute.hidden.md\:flex.md\:items-center.md\:justify-center.right-3.bottom-3.px-4.py-2.rounded-xl.bg-neutral-100.text-neutral-500.cursor-pointer.hover\:bg-neutral-200.z-10 {
   z-index: 1;
}
.min-w-470 {
  min-width: 100%;
}
.from-edit-click form.flex.flex-direction-column.gap-2.w-100.gap-3 > div {
  flex-direction: row;
}
.mt-5.mb-5.from-edit-click .flex.justify-between {
  align-items: flex-start;
  gap: 8px;
}
.from-edit-click form.flex.flex-direction-column.gap-2.w-100.gap-3 > div select {
  padding-right: 10px;
  min-width: 70px;
}
.w-15-btn button.nc-Button:after {
  font-family: 'Line Awesome Free';
  font-weight: 900;
  content: "\f218";
  font-size: 33px;
}

.w-15-btn .bg-primary-6000 {
  background-color: var(--bs-primary) !important;
  color: var(--bs-dark) !important;
  font-size: 0 !important;
  width: 70px;
  min-height: 51px;
}
  }


  
  .container {
    max-width: 1530px !important; 
  }
  .nc-MainNav1.absolute.nav-home1.w-full.z-10.notOnTop.backdrop-filter {
    border-bottom: none !important;
}

.nc-BtnLikeIcon.w-8.h-8.flex.items-center.justify-center.rounded-full.cursor-pointer.text-white.bg-opacity-30.hover\:bg-opacity-50.hover\:bg-black:hover {
  background-color: transparent;
}
.nc-BtnLikeIcon.w-8.h-8.flex.items-center.justify-center.rounded-full.cursor-pointer.text-white.bg-opacity-30.hover\:bg-opacity-50.hover\:bg-black:hover svg {
  fill:#fff
}
.Source-Sans-3.bg-primary-color.bg-red-700.flex.items-center.justify-center.left-\[140px\].nc-SaleOffBadge.px-3.py-0\.5.rounded-full.text-red-50.text-xs.top-3 span {
  text-transform: uppercase;
}

.none-phone.flex.gap-3.items-center.justify-center.none-phone a.bg-white:hover {
  background-color: #fff5ac;
  border-color: #fff5ac;
  color: #222;
}
.focus\:border-primary-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255, 245, 172);
}.focus\:ring-primary-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, 0);
}
.Collapsible__contentInner .nc-CommentListing.flex.space-x-4.pb-3.py-8 {
  padding-top: 0;
}
.Collapsible__contentInner button.nc-Button:hover {
  background-color: #fff5ac !important;
  color: #000 !important;
  border-color: #fff5ac;
}

span.absolute.bottom-3rem.flex.h-14.items-center.justify-center.m-auto.rounded.rounded-\[40px\].top-60.w-14.z-50 {
  margin: auto;
  margin-bottom: 26px;
}

.hover\:bg-neutral-100:hover {
  --tw-bg-opacity: 1;
  background-color: transparent !important;
  color: #000 !important;
}

.details-page-sub-header.listingDescription__wrap {
  margin-bottom: 20px;
}
.details-page-sub-header.listingDescription__wrap .Collapsible__contentInner p {
  margin-top: 10px;
  line-height: 10px;
}
.focus\:border-primary-300:focus {
  --tw-border-opacity: 1;
  border-color: #fff5ac !important;
}
.focus\:ring:focus { 
  box-shadow: #fff5ac !important;;
}.focus\:ring-primary-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255, 251, 220) !important;
}